<template>
  <p class="label" :class="`label--${size}`">
    <template v-if="value"> {{ value }} </template>
    <template v-else>
      {{ label ? `${label}:` : '' }}
      <span class="tba">{{ t('tba') }}</span>
    </template>
  </p>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    label?: string
    value?: string
    size: 'small' | 'big' | 'medium'
  }>(),
  { size: 'medium', label: undefined, value: undefined }
)

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.label {
  @include t-caption-small;

  @include media-up(md) {
    &--big {
      @include t-caption();
    }
  }

  @include media-up(lg) {
    &:not(.label--small) {
      @include t-caption();
    }
  }
}
</style>
